* {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: Abril Fatface;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fff;
    height: 100%;
}

html {
    height: 100%;
}
a {
    text-decoration: none;
    color: black;
}

code {
    font-family: Abril Fatface;
}
/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ff7517;
    border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ff7417b5;
}
